<template>
  <div>
    <div class="d-flex flex-column my-10" v-for="position in positions" v-bind:key="position.id">
      <h3 class="mx-auto mx-sm-2">{{ position.name }}</h3>
      <div class="d-flex align-center flex-wrap justify-xs-center ">
        <v-card
          elevation="4"
          class="mt-2 mx-auto mx-sm-3 pa-3"
          v-for="candidate in candidates.filter(i => i.position.name === position.name)"
          :key="candidate.id"
          width="22%"
          height="130"
          min-width="190"
          align="center"
        >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  class="mx-2 fa-xs restore"
                  @click.stop="unArchiveCandidate(candidate.id)"
                  >fa-trash-restore
                </v-icon>
              </template>
              <span>UnArchive Candidate</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  class="mx-2 fa-xs delete"
                  @click.stop="deleteCandidate(candidate.id)"
                  >fa-times
                </v-icon>
              </template>
              <span>Delete Candidate</span>
            </v-tooltip>
          </v-card-actions>
          <v-card-text class="font-weight-black"
            >{{ candidate.firstName }} {{ candidate.lastName }}</v-card-text
          >
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
export default {
  name: 'candidatesList',
  components: {},
  methods: {
    async unArchiveCandidate(id) {
      await this.$store.dispatch('archivedCandidates/unArchive', id);
    },
    async deleteCandidate(id) {
      await this.$store.dispatch('archivedCandidates/delete', id);
      await this.$store.dispatch('archivedCandidates/load');
    }
  },
  computed: {
    candidates() {
      return this.$store.state.archivedCandidates.list;
    },
    positions() {
      let position = [];
      this.$store.state.archivedCandidates.list.forEach(archivedCandidate => {
        if (!_.findWhere(position, archivedCandidate.position)) {
          position.push(archivedCandidate.position);
        }
      });
      return position;
    }
  },
  created() {
    this.$store.dispatch('archivedCandidates/load');
  }
};
</script>
